$gray: #636363;
$gray-light: #DBDBDB;
$gray-lighter: #F4F4F4;
$gray-darker: #141414;

$body-bg: #ffffff;
$brand-primary: #00B398;
$brand-primary-lighter: lighten($brand-primary, 10%); // non-twbs
$brand-primary-darker: #151527; // non-twbs
$text-shadow: 0 0 1em #24272E; // non-twbs

$grid-gutter-width: 20px;

$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

$transition-length: 333ms; // non-twbs

$text-color: $gray;
$line-height-base: 1.67;
$font-size-base: 15px;
$font-family-base: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-weight: 900;
$headings-color: $gray-darker;
$font-size-h1: 24px;
$font-size-h2: 24px;
$font-size-h3: 20px;
$font-size-h4: 20px;
$font-size-h5: 20px;
$font-size-h6: $font-size-base;
$blockquote-small-color: $text-color;

$navbar-height: 60px;
$navbar-default-bg: transparent;
$navbar-default-border: transparent;
$navbar-default-color: $body-bg;
$navbar-default-link-color: $body-bg;
$navbar-default-link-hover-color: $brand-primary;
$navbar-default-toggle-icon-bar-bg: $brand-primary;
$navbar-default-link-active-color: $brand-primary;
$navbar-default-link-active-bg: $navbar-default-bg;
$navbar-link-bottom-border: 0px; // non-twbs
$navbar-link-top-border: 0px; // non-twbs
$navbar-default-toggle-icon-bar-bg: $text-color;
$navbar-default-toggle-hover-bg: $brand-primary;

// non-twbs
$navbar-desktop-height: 10rem;

//@import '~bootstrap-sass/assets/stylesheets/bootstrap';

// Core variables and mixins
@import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "~bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/print";
//@import "~bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "~bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "~bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "~bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

@import 'slick-carousel/slick/slick.scss';
@import 'slick-carousel/slick/slick-theme.scss';

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

html {
  background: $brand-primary-darker;
}

textarea {
  min-width: 100%;
  max-width: 100%;
}

/* Main navigation */
.navbar-default {
  font-weight: 700;

  .navbar-nav {
    > li > a {
      border-bottom: $navbar-link-bottom-border solid transparent;
      padding-top: 0.5*$navbar-padding-vertical + $navbar-link-top-border;
      padding-bottom: 0.5*$navbar-padding-vertical + $navbar-link-top-border;
      transition: border-bottom-color $transition-length;

      @media screen and (min-width: $grid-float-breakpoint) {
        padding-top: 0.66*$navbar-padding-vertical + $navbar-link-top-border;
        padding-bottom: 0.66*$navbar-padding-vertical + $navbar-link-top-border;
      }

      &:hover {
        border-bottom-color: $brand-warning;
      }
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .navbar-brand {
    padding: 0 $navbar-padding-horizontal 0 0;

    img {
      height: 2rem;
      width: auto;

      @media (min-width: $screen-lg-min) {
        height: 3rem;
        width: auto;
      }
    }
  }

  .navbar-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;

    .navbar-call-me {
      border: 0 solid $navbar-default-toggle-border-color;
      border-left-width: 1px;
      flex: 0 0 $navbar-height;
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: flex;

      .navbar-brand,
      .navbar-call-me,
      .navbar-toggle {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .navbar-brand {
        flex: 1 1 auto;

        img {
          max-width: 100%;
        }
      }
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      align-items: center;

      .navbar-call-me {
        display: none;
      }
    }
  }

  @media screen and (max-width: $grid-float-breakpoint-max) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .navbar-toggle {
    border: 0;
    flex: 0 0 $navbar-height;
    margin: 0;

    .icon-bar {
      @include border-radius(0);
      height: 3px;
      width: 16px;

      + .icon-bar {
        margin-top: 2px;
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    nav {
      display: flex;
    }

    .navbar-collapse {
      flex: 1 1 100%;

      ul {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        float: none;
        justify-content: space-around;
        width: 100%;
      }

      li {
        flex: 0 0 auto;
      }
    }

    .nav > li > a {
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
  }

  @media (min-width: $screen-md-min) {
    .navbar-collapse ul {
      justify-content: flex-start;
    }

    .nav > li > a {
      padding-left: 1.5vw;
      padding-right: 1.5vw;
    }
  }
}

.navbar-call-me {
  &:hover,
  &:focus {
    background-color: $navbar-default-toggle-hover-bg;
  }
}

// Marco's fancy background fix
.navbar-default {
  border: none;
}

@media screen and (max-width: $grid-float-breakpoint-max) {
  .navbar-default {
    margin-bottom: 0;

    &.navbar-fixed-top {
      background: transparent;
    }

    .navbar-header,
    .navbar-nav {
      background: $navbar-default-bg;
    }

    .navbar-header {
      border-bottom: 3px solid $brand-primary;
    }
  }

  .navbar-fixed-top .navbar-collapse {
    background-color: rgba(23, 22, 24, 0.33);
    border: none;
    max-height: 100vh;
    transition: height $transition-length;

    &.in {
      height: 100vh;
    }
  }

  .navbar-collapse {

  }
}

.visible-mobile {
  @media (min-width: $grid-float-breakpoint) {
    display: none !important;
  }
}

.visible-desktop {
  @media (max-width: $grid-float-breakpoint-max) {
    display: none !important;
  }
}

// side-disapearing menu
.navbar-header {
  z-index: 1;
}

@media (max-width: $grid-float-breakpoint-max) {
  body:before {
    content: '';
    display: block;
    height: $navbar-height;
  }

  .navbar-default .navbar-header, .navbar-default .navbar-nav {
    background: $brand-primary-darker;
  }

  .navbar-default .navbar-nav {
    background: $brand-primary-darker;
  }

  .nav > li:last-child {
    border-bottom: 1px solid $brand-primary;
  }
}

@media (min-width: $grid-float-breakpoint) {
  .navbar-toggle {
    display: none
  }

  .navbar-default {
    .navbar-brand {
      align-items: center;
      background: $brand-primary;
      display: flex;
      height: $navbar-desktop-height;
      padding: 0 $grid-gutter-width 0 0;

      .visible-desktop + .visible-desktop {
        margin-left: $grid-gutter-width;
      }
    }

    nav {
      align-items: stretch;
    }

    .navbar-header {
      display: block;
    }

    .navbar-collapse {
      align-items: center;
      display: flex !important;
      max-height: none;
    }
  }

  #navbar {
    background: $brand-primary-darker;
  }

  #top-navigation {
    background: linear-gradient(90deg, $brand-primary 50%, $brand-primary-darker 50%);
  }
}

@media (min-width: $screen-md-min) {
  .nav .offer-page {
    margin-left: 3vw;

    a {
      cursor: pointer;
      line-height: 1em;
      padding: 1.8rem 3.8rem;
      position: relative;

      &:before {
        border: 2px solid $body-bg;
        border-width: 2px 2px 0 0;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 5rem;
        bottom: 0.4rem;
      }

      &:after {
        border: 2px solid $body-bg;
        border-width: 0 0 2px 2px;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 5rem;
        top: 0.4rem;
      }
    }
  }
}

/* End main navigation */

.user-content {
  overflow: hidden;
}

.navbar-brand {
  font-size: $font-size-base;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  strong {
    color: $brand-primary;
    font-weight: inherit;
  }
}

.box-link {
  background: transparent;
  border-left: 2px solid $brand-primary;
  border-bottom: 2px solid $brand-primary;
  color: inherit;
  display: inline-block;
  line-height: 1em;
  padding: 0 0 0.4em 0.5em;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

/* BLOCKS */
.header_home_block {
  position: relative;

  .header {
    color: $body-bg;
    background-size: cover;
    background-position: top right;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    padding-top: 2.5*$grid-gutter-width;
    padding-bottom: 2.5*$grid-gutter-width;
    text-shadow: $text-shadow;

    &:before {
      content: ' ';
      display: block;
      height: $navbar-height;
    }

    > div > :first-child {
      margin-top: 0;
    }

    h1 {
      color: inherit;
      font-size: 3.2rem;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .section {
    > :first-child {
      margin-top: 0;
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    .header {
      p {
        display: none;
      }
    }

    .section {
      padding-top: 2*$grid-gutter-width;
      padding-bottom: 2*$grid-gutter-width;

      &:after {
        background: $gray-lighter;
        content: ' ';
        display: block;
        height: 0.8rem;
        margin-top: 2*$grid-gutter-width;
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    &:before {
      background: $brand-primary-darker;
      content: '';
      display: block;
      height: $navbar-height + (2*$grid-gutter-width);
    }

    .header {
      padding-top: 26rem;
      padding-bottom: 50rem;
      position: relative;

      > div {
        box-sizing: content-box;
        padding-left: 14%;
        width: 58rem;
        position: relative;

        &:before {
          background: $brand-primary;
          content: '';
          height: 1px;
          position: absolute;
          left: -2rem;
          width: 6rem;
          top: 3.6rem;
        }
      }

      h1 {
        font-size: 7.2rem;
      }

      p {
        font-size: 1.7rem;
      }

      &:after {
        content: ' ';
        display: block;
        background: $gray-lighter;
        position: absolute;
        left: 4rem;
        right: 0;
        bottom: -4rem;
        height: 4rem;
      }
    }

    .section {
      background: $body-bg;
      margin-top: -20rem;
      padding: 1.5*$grid-gutter-width;

      h2 {
        position: relative;

        &:before {
          background: $brand-primary;
          content: '';
          height: 1px;
          position: absolute;
          left: -5rem;
          width: 4rem;
          top: 1.3rem;
        }
      }
    }
  }
}

.presentations_intro_block {
  h2 {
    margin: 0 0 2*$grid-gutter-width 0;
  }

  .presentation {
    background: $gray-darker;
    color: $body-bg;
    overflow: hidden;
    padding: 2*$grid-gutter-width 20% 2*$grid-gutter-width 2*$grid-gutter-width;
    position: relative;
    text-shadow: $text-shadow;

    + .presentation {
      border-top: 1px solid $gray;
    }

    h3 {
      color: $brand-primary;
      margin: 0;
    }

    h4 {
      color: inherit;
      font-weight: 500;
    }

    .body {
      position: relative;
      z-index: 1;
    }

    .image {
      z-index: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      div {
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        right: -10%;
        bottom: 0;
        left: 10%;
        z-index: 0;
      }

      &:before {
        background: #000;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 40%;
        bottom: 0;
        left: 0;
        z-index: 1;
      }

      &:after {
        display: block;
        position: absolute;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0, #000 100%);
        top: 0;
        right: 0;
        bottom: 0;
        left: 60%;
        content: '';
        z-index: 1;
      }
    }
  }

  .links {
    background: $gray-lighter;
    display: flex;
    padding: $grid-gutter-width $grid-gutter-width/2;

    a {
      flex: 1 1 50%;
      margin: 0 $grid-gutter-width/2;
      text-align: center;
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    .links,
    .presentations {
      margin-left: -$grid-gutter-width/2;
      margin-right: -$grid-gutter-width/2;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    h2 {
      margin-top: 2*$grid-gutter-width;
      text-align: center;
    }

    .presentations {
      display: flex;
    }

    .presentation {
      flex: 0 0 50%;

      &:first-child {
        margin-right: $grid-gutter-width/2;
      }

      &:last-child {
        margin-right: $grid-gutter-width/2;
      }
    }

    .links {
      margin-left: 25%;
      margin-right: 10%;
      padding: 2*$grid-gutter-width 10%;
    }

    .body {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
  }
}

.testimonials_block {
  margin-top: 4*$grid-gutter-width;

  .text-center {
    margin-bottom: 3*$grid-gutter-width;

    h2 {
      margin: 0;
    }

    h3 {
      font-size: 1.6rem;
      font-weight: 700;
      margin: 0.5em 0 0 0;
    }
  }

  img {
    height: 3*$grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    max-width: 100%;
    width: auto;
  }

  &.carousel {
    .container:after {
      background: $gray-lighter;
      height: $grid-gutter-width;
      width: 100%;
    }

    blockquote {
      border: none;
      flex-direction: column;
      font-size: inherit;
      height: auto;
      margin: 0;
      padding: 0 2*$grid-gutter-width 2*$grid-gutter-width 2*$grid-gutter-width;
      position: relative;

      &:before {
        background: $gray-lighter;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        z-index: 1;
      }

      &:before {
        left: -1px;
      }

      footer {
        margin-top: $grid-gutter-width;

        &:before {
          content: '';
        }
      }
    }

    .author {
      color: $gray;
      display: block;
      font-size: $font-size-base;
      font-weight: bold;
    }

    .author-affiliation {
      display: block;
      font-size: 1.3rem;
      font-style: italic;
    }

    .slick-track {
      display: flex;
      align-content: stretch;
    }

    .slick-list {
      &:after {
        background: $body-bg;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
        z-index: 2;
      }
    }

    .slick-dots {
      margin: 2*$grid-gutter-width $grid-gutter-width;
    }

    .slick-arrow {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: center center no-repeat;
      border: none;
      display: block;
      font-size: 0;
      line-height: 0;
      height: 30px;
      width: 30px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      z-index: 999;

      &:after {
        background: $brand-primary;
        content: '';
        display: block;
        opacity: 0.1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: $transition-length opacity;
      }

      &:hover:after {
        opacity: 0.2;
      }
    }

    .slick-prev {
      background-image: url(../resources/arrow-left-groen.svg);
      left: 0;
    }

    .slick-next {
      background-image: url(../resources/arrow-right-groen.svg);
      right: 0;
    }

    .slick-prev,
    .slick-next {
      &:before {
        display: none;
      }
    }
  }

  &.expanded {
    padding: $grid-gutter-width 0;

    blockquote {
      border-color: $gray-lighter;
      border-style: solid;
      border-width: 0 0 1px 0;
      font-size: inherit;
      margin: 0;
      padding: 2*$grid-gutter-width $grid-gutter-width;

      &:last-child {
        border-bottom: 0;
      }

      footer {
        font-size: inherit;
        font-weight: 700;

        &:before {
          content: none;
        }
      }

      .author {
        color: $gray-darker;
      }

      .author-affiliation {
        color: $brand-primary;
      }

      .author + .author-affiliation:before {
        color: $gray-darker;
        content: ',';
        margin: 0 0.2rem 0 -0.2rem;
      }
    }

    @media (min-width: $grid-float-breakpoint) {
      padding: 2*$grid-gutter-width 0;

      .testimonials-expanded {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      blockquote {
        flex: 0 0 50%;
        padding: 2*$grid-gutter-width 1.5*$grid-gutter-width;

        &:nth-child(odd) {
          border-right: 1px solid $gray-lighter;
        }

        &:nth-last-child(2):nth-child(odd) {
          border-bottom: 0;
        }
      }
    }
  }
}

.books_block {
  &:after {
    background: $gray-lighter;
    content: '';
    display: block;
    height: 6vh;
    margin-top: 2*$grid-gutter-width;
    min-height: $grid-gutter-width;
  }

  .container {
    margin-top: -$grid-gutter-width;
  }

  .header-group {
    align-items: center;
    background: url(../resources/wood.jpg) center center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 34.2rem;
    justify-content: center;
    overflow: hidden;
    position: relative;

    > img {
      height: 75%;
      width: auto;
    }
  }

  .actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 6rem;

    > * {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 6rem;
      justify-content: center;
      width: 6rem;
    }

    span {
      background: $brand-primary;
      color: $body-bg;
      padding-top: 0.3rem;
    }

    a {
      background: $gray-darker;
    }
  }

  h1[itemprop="name"] {
    margin-bottom: 0;
  }

  h2[itemprop="alternativeHeadline"] {
    margin-top: 0.5rem;
  }

  article {
    padding-bottom: $grid-gutter-width;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      &:nth-child(odd) {
        clear: both;
      }
    }

    @media (min-width: $screen-md-min) {
      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }
}

.presentations_block {
  counter-reset: presentations;

  .presentation {
    counter-increment: presentations;
  }

  .wrapper:nth-child(even) {
    background: $gray-lighter;
  }

  .portrait-image {
    display: none;
  }

  h1 {
    margin-bottom: 0;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 0.3rem;
  }

  .header-group {
    position: relative;
  }

  .actions {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: -$grid-gutter-width;
  }

  .wrapper:nth-child(odd) .actions {
    left: -$grid-gutter-width/2;
  }

  .wrapper:nth-child(even) {
    .actions {
      right: -$grid-gutter-width/2;
    }

    .counter {
      order: -1;
    }
  }

  .video {
    background: $brand-primary center center no-repeat;
    display: block;
    height: 8.7rem;
    position: relative;
    width: 8.7rem;

    &:after {
      background: $brand-primary;
      content: '';
      display: block;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    img {
      position: absolute;
      margin: -13px 0 0 -13px;
      left: 50%;
      top: 50%;
      z-index: 2;
    }

    .image {
      background: center center no-repeat;
      background-size: cover;
      filter: grayscale(1);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
    }
  }

  .counter {
    align-items: center;
    align-content: center;
    background: $brand-primary-darker;
    color: $body-bg;
    display: flex;
    justify-content: center;
    height: 4.9rem;
    width: 4.9rem;

    &:after {
      content: '#' counter(presentations);
      font-family: $headings-font-family;
      font-size: 1.3rem;
    }
  }

  .content {
    padding: $grid-gutter-width 0 2*$grid-gutter-width;
  }

  .request-offer {
    color: $gray;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 1em;
    margin: 2rem 0 0;
    padding: 1.8rem 3.8rem;
    position: relative;
    text-decoration: none;

    &:before {
      border: 2px solid $gray-light;
      border-width: 2px 2px 0 0;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 5rem;
      bottom: 0.4rem;
    }

    &:after {
      border: 2px solid $gray-light;
      border-width: 0 0 2px 2px;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 5rem;
      top: 0.4rem;
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    h1 {
      font-size: 2rem;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    .request-offer {
      display: none;
    }

    .wrapper:nth-child(odd) .actions {
      align-items: left;
    }

    .presentation {
      display: flex;
      flex-direction: row;
    }

    .actions {
      bottom: auto;
      flex-direction: column;
      top: 2*$grid-gutter-width;
    }

    .content {
      padding-bottom: 7*$grid-gutter-width;
    }

    .wrapper:nth-child(odd) {
      .actions {
        left: -6rem;
      }

      .content {
        order: -1;
        padding-right: 4*$grid-gutter-width;
      }
    }

    .wrapper:nth-child(even) {
      .actions {
        left: auto;
        right: -6rem;
      }

      .content {
        padding-left: 4*$grid-gutter-width;
      }

      .counter {
        order: 0;
      }
    }

    .header-group {
      flex: 0 0 39%;
      margin-top: -2*$grid-gutter-width;
      margin-bottom: 2*$grid-gutter-width;
    }

    .video {
      height: 12rem;
      width: 12rem;
    }

    .counter {
      height: 6rem;
      width: 6rem;
    }

    .landscape-image {
      display: none;
    }

    .portrait-image {
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      max-height: 100%;

      img {
        height: auto;
        width: 100%;
      }

      > span {
        padding: 1.5*$grid-gutter-width;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
      }

      a {
        color: $body-bg;
        cursor: pointer;
        display: inline-block;
        flex: 0 0 auto;
        font-weight: 700;
        line-height: 1em;
        margin: 2rem 0 0;
        padding: 1.8rem 3.8rem;
        position: relative;
        text-decoration: none;

        &:before {
          border: 2px solid $body-bg;
          border-width: 2px 2px 0 0;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 5rem;
          bottom: 0.4rem;
        }

        &:after {
          border: 2px solid $body-bg;
          border-width: 0 0 2px 2px;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 5rem;
          top: 0.4rem;
        }
      }
    }

    .wrapper:first-child .header-group {
      .header-group {
        margin-top: -4*$grid-gutter-width;
      }
    }
  }
}

.text_block {
  background: $gray-lighter;
  padding: 2*$grid-gutter-width 0;

  @media (min-width: $grid-float-breakpoint) {
    padding: 3*$grid-gutter-width 0;
  }
}

ul.social_media {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: block;
    float: left;

    + li {
      margin-left: $grid-gutter-width/2;
    }
  }

  a {
    align-items: center;
    display: flex;
    height: 4rem;
    justify-content: center;
    width: 4rem;
  }

  .twitter {
    background: #1DA1F2;
  }

  .email {
    background: $brand-primary;
  }

  .linkedin {
    background: #007AB9;
  }

  @media (min-width: $grid-float-breakpoint) {
    a {
      height: 4.5rem;
      width: 4.5rem;
    }
  }
}

/* TODO: solve vertical social media icons */
.socialmedia_block {
  &.vertical {
    a {
      background: $gray-darker;
    }
  }

  &.horizontal {
    background: $gray-lighter;
    padding: 2*$grid-gutter-width 0;
  }

  @media (min-width: $grid-float-breakpoint) {
    &.horizontal {
      padding: 3*$grid-gutter-width 0;
    }
  }
}

.videos_block {
  .header-group {
    background: center 20% no-repeat;
    background-size: cover;
    display: block;
    height: 25vh;
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;
    min-height: 10rem;
    position: relative;
  }

  .arrow {
    background: $gray-darker;
    display: block;
    height: 4rem;
    left: 50%;
    margin: -2rem 0 0 -2rem;
    position: absolute;
    top: 50%;
    width: 4rem;

    img {
      left: 50%;
      margin: -8.5px 0 0 -6px;
      position: absolute;
      top: 50%;
    }
  }

  article {
    padding-bottom: $grid-gutter-width;

    @media (max-width: $grid-float-breakpoint-max) {
      + article {
        padding-top: $grid-gutter-width;
      }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      &:nth-child(odd) {
        clear: both;
      }
    }

    @media (min-width: $screen-md-min) {
      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    .container {
      margin-top: -2*$grid-gutter-width;
    }

    .header-group {
      height: auto;
      margin: 0;
    }

    .arrow {
      height: 6rem;
      margin: -3rem 0 0 -3rem;
      width: 6rem;
    }

    .square {
      display: block;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
  }
}

.image-block {
  background: center 20% no-repeat;
  background-size: cover;
  height: 33vh;
  min-height: 10rem;
}

.offer_form_block {
  .image-wrap {
    img {
      height: auto;
      display: none;
      width: 100%;
    }
  }

  .social_media {
    padding: $grid-gutter-width 0;
  }

  @media (min-width: $grid-float-breakpoint) {
    .container {
      background: $body-bg;
      display: flex;
      margin-top: -2*$grid-gutter-width;
    }

    .content {
      flex: 0 0 65%;
      padding: $grid-gutter-width/2 2*$grid-gutter-width $grid-gutter-width $grid-gutter-width;
    }

    .header-group {
      flex: 0 0 35%;
      margin-top: -$grid-gutter-width;
    }

    .image-wrap {
      margin-right: -10px;

      img {
        display: block;
      }
    }
  }
}

.profile_block {
  .header-group {
    position: relative;
  }

  .portrait-image {
    display: none;
  }

  .content {
    padding-top: $grid-gutter-width/2;
  }

  .links {
    list-style: none;
    padding: 0;

    li {
      padding: 3rem 0;
    }

    a {
      color: $gray-darker;
      align-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      font-family: $headings-font-family;
      justify-content: flex-start;
    }

    strong {
      color: $brand-primary;
    }

    .arrow {
      background: $brand-primary;
      display: inline-block;
      height: 3rem;
      flex: 0 0 3rem;
      margin-right: 1rem;
      position: relative;
      width: 3rem;

      img {
        left: 50%;
        margin: -5.5px 0 0 -3.5px;
        position: absolute;
        top: 50%;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .links li + li {
      border-top: 1px solid $gray-lighter;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    .container {
      display: flex;
      margin-top: -2*$grid-gutter-width;
    }

    .header-group {
      flex: 0 0 44%;
      margin-top: -2*$grid-gutter-width;
    }

    .content {
      background: $body-bg;
      order: -1;
    }

    .landscape-image {
      display: none;
    }

    .portrait-image {
      bottom: 2*$grid-gutter-width;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        border-bottom: 1px solid $gray-lighter;
        flex: 0 0 50%;

        &:nth-child(odd) {
          border-right: 1px solid $gray-lighter;
          padding-right: 3rem;
        }

        &:nth-child(even) {
          padding-left: 3rem;
        }

        &:nth-last-child(1),
        &:nth-last-child(2):nth-child(odd) {
          border-bottom: none;
        }
      }
    }
  }
}

.header_regular_block {
  h1 + h3 {
    font-size: 1em;
    margin: 0 0 $padding-large-horizontal 0;
  }

  .header-group {
    position: relative;
  }

  .content {
    padding-top: $grid-gutter-width/2;
  }

  .portrait-image {
    display: none;
  }

  @media (min-width: $grid-float-breakpoint) {
    .container {
      display: flex;
      margin-top: -2*$grid-gutter-width;
    }

    .header-group {
      flex: 0 0 44%;
      margin-top: -2*$grid-gutter-width;
    }

    .content {
      background: $body-bg;
      order: -1;
    }

    .landscape-image {
      display: none;
    }

    .portrait-image {
      display: block;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
}

/* Common for blocks */
/* LinkedIn button same on profile and regular block */
.header_regular_block,
.profile_block {
  .lin {
    align-items: flex-end;
    bottom: -$grid-gutter-width;
    display: flex;
    left: -$grid-gutter-width/2;
    flex-direction: row;
    position: absolute;
  }

  .lin_logo {
    background: $brand-primary;
    display: inline-block;
    height: 9rem;
    position: relative;
    width: 9rem;

    img {
      left: 50%;
      margin: -10px 0 0 -10px;
      position: absolute;
      top: 50%;
    }
  }

  .arrow {
    align-items: center;
    background: $gray-darker;
    display: flex;
    justify-content: center;
    height: 4rem;
    width: 4rem;
  }

  @media (min-width: $grid-float-breakpoint) {
    .content {
      padding: $grid-gutter-width 7rem $grid-gutter-width 2*$grid-gutter-width;
    }

    .lin {
      align-items: flex-start;
      bottom: auto;
      flex-direction: column;
      left: -6rem;
      top: 0;
    }

    .lin_logo {
      height: 12rem;
      width: 12rem;
    }

    .arrow {
      height: 6rem;
      width: 6rem;
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  .books_block,
  .presentations_block,
  .profile_block,
  .videos_block,
  .offer_form_block,
  .header_regular_block {
    &:before {
      background: $brand-primary-darker;
      content: '';
      display: block;
      height: 20vh;
      min-height: $navbar-height + (8*$grid-gutter-width);
    }
  }
}

.presentations_block,
.profile_block,
.header_regular_block {
  .landscape-image {
    background: $gray-lighter top right no-repeat;
    background-size: cover;
    height: 22vh;
    min-height: 21rem;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
  }
}

/* End BLOCKS */

/* FOOTER */
#footer {
  background: $brand-primary-darker;
  color: $body-bg;
  font-size: 1.2rem;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;

  a {
    color: inherit;
    text-decoration: none;
  }

  span {
    display: block;
    text-align: center;
  }

  strong {
    font-size: $font-size-base;
    font-weight: 700;
    padding-right: 0.4em;
  }

  @media (min-width: $grid-float-breakpoint) {
    .container {
      display: flex;
      align-items: center;

      span {
        flex: 0 0 50%;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

/* end FOOTER */

/* Slick */
.slick-dots {
  display: block;
  padding: 0;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    &.slick-active button {
      background: $brand-primary;
    }

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 1rem;
      height: 1rem;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border-radius: 0.5rem;
      border: 0;
      outline: none;
      background: $gray-lighter;

      &:hover,
      &:focus {
        outline: none;
      }

      &:before {
        display: none;
      }
    }
  }
}

/* Slick */
.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;

  @media (min-width: $grid-float-breakpoint) {
    > div:before {
      content: ' ';
      display: block;
      height: $navbar-height + (2*$grid-gutter-width);
    }
  }
}
